@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import 'bootstrap/scss/bootstrap';
@import 'intl-tel-input/build/css/intlTelInput.css';

body {
  font-family: 'Helvetica Neue', sans-serif;
  text-align: center;
  color: #18262f;
  .container {
    max-width: 1400px;
  }
}
.modal-backdrop.show {
  opacity: 0.8;
  margin-right: auto;
}

#trustpilot-grid {
  background-color: #f9f9f9;
  padding: 40px 0px;
}

#terms {
  text-align: left;
}

#privacy {
  text-align: left;
}

#thanks {
  padding-bottom: 120px;
  img {
    width: 100px;
    height: auto;
    margin: auto;
    padding-bottom: 60px;
    padding-top: 120px;
  }
}

form {
  .has-error {
    //color: #dd4b39 !important;
    background-color: #fff !important;
    border: 2px solid #ff5f5f !important;
  }
  .has-error:focus {
    border-bottom: 3px solid #ff5f5f !important;
    box-shadow: 0 0 0 0 rgba(255, 12, 0, 0.25) !important;
  }

  #roundTripDepart.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
  #roundTripReturn.has-error {
    background-color: #fff3f3;
    color: #dd4b39 !important;
  }
}

.active-datepicker {
  border-bottom: 3px solid #0065b8;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.25);
}

.iti {
  width: 100%;
  position: relative;
  display: block;

  .iti__selected-flag {
    padding: 0 0 0 20px !important;

    .iti-flag {
      margin-top: 35px !important;
    }

    .iti-arrow {
      margin-top: 10px !important;
      right: 0px !important;
    }
  }

  .iti__country-list {
    .country {
      padding: 5px 10px;
      white-space: normal;
    }
  }

  .iti__country-list {
    .iti__country {
      padding: 5px 10px;
      white-space: normal;
    }
  }

  .iti__flag-container:hover .iti__selected-flag {
    background-color: inherit;
  }
}

.mat-mdc-option.mdc-list-item--selected {
  background: inherit !important;

  span {
    color: inherit;
  }

  mat-pseudo-checkbox {
    display: none;
  }
}

.cdk-overlay-container:has(.mat-mdc-autocomplete-panel) {
  z-index: 1100;
}

.city-header {
  p {
    font-size: 20px;
  }
  b {
    color: #ff5f5f;
  }

  ol {
    font-size: 20px;
    text-align: left;
    list-style-position: inside;

    li {
      margin-bottom: 1rem;
    }
  }
}

.btn-link {
  color: #ff5f5f;
}
.btn-link:hover {
  color: #ff5f5f;
}
.ngb-dp-weekday {
  color: #ff5f5f !important;
}

.bg-primary {
  background-color: #ff5f5f !important;
}
